import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Seo from "components/Seo"
import Layout from "components/Layout"
import LabCard from "components/LabCard"

const getImages = graphql`
  {
    vanImage: file(relativePath: { eq: "balcon-service-van-no.webp" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    seoImg: file(relativePath: { eq: "placeholder-lab.webp" }) {
      childImageSharp {
        fixed(width: 400, height: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    plcHolder: file(relativePath: { eq: "placeholder-lab.webp" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    smokePat: file(
      relativePath: { eq: "balcon-lab-safety-smoke-pattern-testing.webp" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    tracerGas: file(
      relativePath: { eq: "balcon-lab-safety-tracer-gas-containment.webp" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    airBal: file(
      relativePath: { eq: "balcon-tab-testing-air-balancing-1.webp" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const NotFoundPage = () => {
  const imgData = useStaticQuery(getImages)
  return (
    <Layout>
      <Seo
        title="Page Not Found | BalCon Lab Safety and TAB Testing Services"
        description="We can't find the page you are looking for. Please view our navigation menu to find the content you're looking for."
        keywords="balcon 404"
        pathname="/404/"
        image={imgData.seoImg.childImageSharp.fluid}
      />
      <article className="container-404">
        <section className="container-404__section  u-p-5">
          <div className=" u-center-text ">
            <h1 className="main-type main-type__black main-type__header-primary main-type__heavy">
              You just hit a route that does not exist
            </h1>
            <div className="u-pos-rel">
              <div className="container-404__img u-center-text">
                <Img
                  fluid={imgData.vanImage.childImageSharp.fluid}
                  alt="BalCon Service Image"
                />
              </div>
            </div>
          </div>
        </section>

        <section className=" container-404__links u-p-2">
          <h2 className="main-type main-type__black main-type__header-primary main-type__heavy u-center-text">
            Try these links instead
          </h2>
          <div className=" grid grid-three-col">
            <LabCard
              title="Lab Safety Services"
              body="For over 25 years, Balcon’s Lab Safety Services Team has been ensuring the safety of people, equipment, and lab environments. By exceeding all national standards, our Lab Safety Group has become the leader in comprehensive testing, certification and service."
              link="/services/lab-safety"
              cardImg={imgData.smokePat.childImageSharp.fluid}
              cardAlt="Balcon Lab Safety technician performing a smoke test on a biological safety cabinet"
            />
            <LabCard
              title="TAB Testing"
              body="Our TAB Testing Division maximizes equipment efficiency and minimizes operating costs. Balanced buildings lower overall energy costs and increase productivity of mechanical systems and employees. We also analyze specifications to avoid construction deficiencies and make sure the engineer’s design is met."
              link="/services/tab-testing"
              cardImg={imgData.airBal.childImageSharp.fluid}
              cardAlt="BalCon TAB Technician monitoring air readings"
            />
            <LabCard
              title="BalCon Blog"
              body="We write weekly content about best practices for Lab Safety and TAB Testing. Do you have question for us? Use the form below and we will be in touch!"
              link="/blog"
              cardImg={imgData.tracerGas.childImageSharp.fluid}
              cardAlt="BalCon Lab Technician monitoring tracer gases in a fume hood"
            />
          </div>
        </section>
      </article>
    </Layout>
  )
}

export default NotFoundPage
