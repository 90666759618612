import React from 'react';
import {Link} from 'gatsby';
import Img from 'gatsby-image';
import Button from 'components/Button';


export default function LabCard({
  title,
  body,
  link,
  cardImg,
  cardAlt,
}) {
  return (
    <div className="lab-card">
      <div className="lab-card__imgcontainer">
        <Img
          fluid={cardImg}
          alt={cardAlt}
          className="lab-card__imgcontainer-img"
        />
      </div>
      <div className="lab-card__body">
        <h2 className="main-type main-type__black main-type__header-tertiary main-type__heavy ">
          {title}
        </h2>
        <p className="main-type main-type__body main-type__black u-mt-1">
          {body}
        </p>
        <div className="lab-card__links ">
          <Link to={link} className="">
            <Button
              text={`Learn more about ${title}`}
              color="blue"
              primary={false}
              margin=""
            />
          </Link>
          {/* <AnchorLink
            to="services/lab-safety#contactForm"
            title="Schedule Service"
            className="btn btn-simple btn-simple__secondary"
          /> */}
        </div>
      </div>
    </div>
  );
}
